import { SmileOutlined } from "@ant-design/icons";
import React, { useEffect, useRef, useState } from "react";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import "./CustomEmojiPicker.scss";

export type Emoji = {
    id: string;
    native: string;
};
type CustomEmojiPickerProps = {
    onEmojiSelect?: (e: Emoji) => void;
};

export default function CustomEmojiPicker({ onEmojiSelect }: CustomEmojiPickerProps) {
    const [toggleEmojiPicker, setToggleEmojiPicker] = useState(false);
    const emojiPickerRef = useRef<HTMLDivElement>(null);
    const [emojiPickerPosPx, setEmojiPickerPosPx] = useState({ top: 0, left: 0 });

    useEffect(() => {
        // Hide <Picker/> if click outside it
        const handleClickOutside = (event: MouseEvent) => {
            if (
                emojiPickerRef.current &&
                !emojiPickerRef.current.contains(event.target as Node) &&
                toggleEmojiPicker
            ) {
                setToggleEmojiPicker(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [toggleEmojiPicker]);

    const onClickToggleEmoji = (toggled: boolean) => {
        if (toggled === true) {
            setToggleEmojiPicker(false);
            return;
        }
        const top = emojiPickerRef?.current?.offsetTop!;
        const left = emojiPickerRef?.current?.offsetLeft!;
        setEmojiPickerPosPx({ top, left });
        setToggleEmojiPicker(true);
    };

    return (
        <>
            <SmileOutlined
                className="smile-button"
                onClick={() => onClickToggleEmoji(toggleEmojiPicker)}
                id="emoji-button"
            />
            {toggleEmojiPicker && (
                <div
                    style={{
                        position: "absolute",
                        top: emojiPickerPosPx.top,
                        left: emojiPickerPosPx.left,
                        zIndex: 1000,
                    }}
                    ref={emojiPickerRef}
                >
                    <Picker
                        data={data}
                        onEmojiSelect={(e: Emoji) => (onEmojiSelect ? onEmojiSelect(e) : () => {})}
                    />
                </div>
            )}
        </>
    );
}
