import { ExportOutlined, PhoneFilled } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import "./WhatsappMessagePreview.scss";
import { RcFile } from "antd/lib/upload";
import WhatsappDownloadIcon from "./WhatsappDownloadIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare } from "@fortawesome/free-solid-svg-icons";
import { Typography } from "antd";

const { Text } = Typography;

interface WhatsappMessagePreviewProps {
    values?: {
        templateBodyExample: string;
        /**
         * @deprecated use quickReplyButtons instead
         */
        quickReplyButton?: string;
        phoneNumberButtonText?: string;
        urlButtonText?: string;
        file?: RcFile;
        quickReplyButtons?: Array<string>;
        footer?: string;
    };
    fileUrl?: string;
}
function getLocalTime(): string {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");

    return `${hours}:${minutes}`;
}

type AttachedFile = { type: "IMAGE"; url: string } | { type: "PDF"; fileName: string; url: string };

function checkFileType(url: string): "PDF" | "IMAGE" | "UNKNOWN" {
    const upperUrl = url.toUpperCase();
    if (upperUrl.includes("PDF")) return "PDF";
    else if (upperUrl.includes("PNG") || upperUrl.includes("JPG") || upperUrl.includes("JPEG")) {
        return "IMAGE";
    }
    return "UNKNOWN";
}

/**
 * Converts a string with markdown-like formatting to JSX components.
 * Supports italic (_text_) and bold (*text*) formatting.
 * Recursively processes nested formatting and prevents XSS attacks.
 *
 * @example
 * const result = convertToJSX("Hello _italic_ and *bold* text");
 * // Returns: <>Hello <em>italic</em> and <strong>bold</strong> text</>
 */
function convertToJSX(text?: string): React.ReactNode {
    if (!text) return <></>;
    const italicRegex = /_(.*?)_/;
    const boldRegex = /\*(.*?)\*/;

    const italicMatch = text.match(italicRegex);
    const boldMatch = text.match(boldRegex);

    if (italicMatch && (!boldMatch || italicMatch.index! < boldMatch.index!)) {
        const [fullMatch, content] = italicMatch;
        const parts = text.split(fullMatch);
        return (
            <>
                {convertToJSX(parts[0])}
                <em>{convertToJSX(content)}</em>
                {convertToJSX(parts[1])}
            </>
        );
    }

    if (boldMatch) {
        const [fullMatch, content] = boldMatch;
        const parts = text.split(fullMatch);
        return (
            <>
                {convertToJSX(parts[0])}
                <strong>{convertToJSX(content)}</strong>
                {convertToJSX(parts[1])}
            </>
        );
    }
    return text;
}

export default function WhatsappMessagePreview({ values, fileUrl }: WhatsappMessagePreviewProps) {
    const [attachedFile, setAttachedFile] = useState<AttachedFile>();

    const templateHasCallToActionButtons = () => {
        if (values?.phoneNumberButtonText || values?.urlButtonText) return true;
        return false;
    };
    const renderPhoneCallButtonText = () => {
        if (values?.phoneNumberButtonText) {
            return (
                <div className="ws-button">
                    <PhoneFilled />
                    {values.phoneNumberButtonText}
                </div>
            );
        }
        return <></>;
    };
    const renderUrlButtonText = () => {
        if (values?.urlButtonText) {
            return (
                <div className="ws-button">
                    <ExportOutlined />
                    {values.urlButtonText}
                </div>
            );
        }
        return <></>;
    };

    const renderQuickReplyButton = () => {
        if (values?.quickReplyButtons) {
            const bts = values.quickReplyButtons;
            return bts.map((b) => (
                <div className="quick-reply-button">
                    <FontAwesomeIcon
                        //@ts-ignore
                        icon={faShare}
                        style={{
                            transform: "scaleX(-1)",
                            marginRight: ".4rem",
                            marginTop: ".8%",
                        }}
                    />
                    {b}
                </div>
            ));
        }
        if (values?.quickReplyButton) {
            return <div className="quick-reply-button">{values.quickReplyButton}</div>;
        }
        return <></>;
    };

    useEffect(() => {
        if (values?.file) {
            const reader = new FileReader();
            reader.readAsDataURL(values.file);
            reader.onload = () => {
                const url = reader.result as string;
                if (values!.file!.type === "application/pdf") {
                    setAttachedFile({ type: "PDF", fileName: values!.file!.name, url });
                } else {
                    setAttachedFile({ type: "IMAGE", url: reader.result as string });
                }
            };
        } else if (fileUrl) {
            const fileType = checkFileType(fileUrl);
            if (fileType === "IMAGE") setAttachedFile({ type: "IMAGE", url: fileUrl! });
            else if (fileType === "PDF") {
                const fileName = fileUrl!.split("/").pop() as string;
                setAttachedFile({ type: "PDF", fileName, url: fileUrl });
            } else {
                setAttachedFile(undefined);
            }
        } else {
            setAttachedFile(undefined);
        }
    }, [values?.file, fileUrl]);

    const renderAttachedFile = () => {
        if (attachedFile?.type === "IMAGE") {
            return (
                <div
                    style={{
                        margin: "-.75rem -.75rem 1rem",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <img
                        src={attachedFile.url}
                        alt="Whatsapp file preview"
                        style={{ maxWidth: "100%", borderRadius: ".5rem" }}
                    />
                </div>
            );
        }

        if (attachedFile?.type === "PDF") {
            return (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#F5F6F6",
                        padding: "1rem",
                    }}
                >
                    <img src="/whatsapp_pdf_icon.png" alt="" style={{ maxHeight: "3rem" }} />
                    <label>{attachedFile.fileName}</label>
                    <WhatsappDownloadIcon
                        style={{ marginLeft: "auto", marginRight: "0" }}
                        url={attachedFile.url}
                    />
                </div>
            );
        }
        return <></>;
    };

    const renderTemplateText = () => {
        if (!values) return <></>;
        return (
            <>
                <div style={{ whiteSpace: "pre-line", paddingLeft: ".5rem", marginTop: ".5rem" }}>
                    {convertToJSX(values?.templateBodyExample)}
                </div>
                {values.footer && (
                    <div style={{ paddingLeft: ".5rem" }}>
                        <Text type="secondary" style={{ fontSize: ".9rem" }}>
                            {values.footer}
                        </Text>
                    </div>
                )}
                {values?.templateBodyExample && (
                    <div
                        className="ws-message-time"
                        style={{
                            fontSize: "0.85rem",
                            color: "#667781",
                            display: "flex",
                            justifyContent: "right",
                            marginBottom: "-0.7rem",
                        }}
                    >
                        {getLocalTime()}
                    </div>
                )}
            </>
        );
    };

    return (
        <div
            className="whatsapp-background-image"
            style={{ backgroundImage: 'url("/whatsapp_background.jpg")' }}
        >
            <div className="whatsapp-message-inner-wrapper">
                {renderAttachedFile()}
                {templateHasCallToActionButtons() ? (
                    <>
                        {renderTemplateText()}
                        {renderPhoneCallButtonText()}
                        {renderUrlButtonText()}
                    </>
                ) : (
                    renderTemplateText()
                )}
            </div>
            {renderQuickReplyButton()}
        </div>
    );
}
